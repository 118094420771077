<template>
  <div class="ad-consume padding-20 box-shadow-light-grey">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <el-date-picker
          v-model="params.selectTime"
          style="width: 330px"
          type="daterange"
          align="right"
          unlink-panels
          :clearable="false"
          range-separator="-"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="yesterdayOptions"
        >
        </el-date-picker>
        <!-- 字段选择 -->
        <select-field
          :fieldsData="fieldsData"
          :originFeild="originFeild"
          :EchoField="sendField"
          @fieldChange="handleFieldChange"
        />
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 10px"
          :loading="loading"
        >
          搜索
        </el-button>
        <el-button type="info" @click="handleReset" :loading="loading">
          重置
        </el-button>
      </div>
    </div>

    <div style="overflow: auto; position: relative" id="userRankTable">
      <!-- 表格头部 -->
      <div
        class="customTable table-header"
        id="userRankTableHeader"
        style="min-width: 100%"
      >
        <div v-for="(item, index) in dataList" :key="index + 'header'">
          <div class="item-head">{{ item.date }}</div>
        </div>
      </div>

      <div
        class="customTable table-body"
        style="min-width: 100%; height: 650px"
      >
        <div class="table-item" v-for="(item, index) in dataList" :key="index">
          <!-- <div class="item-head">{{ item.date }}</div> -->
          <ul
            class="single-user"
            v-for="(item2, index2) in item.list"
            :key="index2"
          >
            <li>
              <div v-for="(item3, index3) in filFields" :key="index3">
                <span>{{ item3.fieldName }}：</span>
                <span :style="'color:' + item3.fontColor">{{
                  item2[item3.fieldCode]
                }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatThousandsDots, getRecentlyWeek } from "@/assets/js/utils";
import SelectField from "@/components/SelectField";
import { getUserEverydayRanking, getFields } from "@/api/statics";
import {
  yesterdayOptions,
  labelList,
  pickerOptions,
} from "@/assets/js/options";
import { mapActions } from "vuex";
let timer = null;
export default {
  name: "userRank",
  components: {
    SelectField,
  },
  computed: {
    // 排序处理
    sortableType() {
      return function (value) {
        if (value.autoFields === 0) {
          return false;
        } else if (value.autoFields === 2 || value.autoFields === 4) {
          return true;
        } else if (value.autoFields === 1 || value.autoFields === 3) {
          return "custom";
        }
      };
    },
    // 文字对齐处理
    filAlign() {
      return function (value) {
        switch (value.fontAlign) {
          case 0:
            return "left";
          case 1:
            return "center";
          case 2:
            return "right";
          default:
            return "center";
        }
      };
    },
    filFields: function () {
      return this.showField.filter(function (item) {
        return item.autoFields !== 1;
      });
    },
  },
  filters: {
    sortField: function (value, item) {
      if (item.autoFields === 4 || item.autoFields === 5) {
        // 报错处理
        if (value !== undefined) {
          return formatThousandsDots(value.toString());
        }
      } else {
        return value;
      }
    },
  },
  data() {
    return {
      params: {},
      bookList: null,
      fieldsData: [],
      originFeild: [],
      selectFields: null,
      selectAllFields: false,
      dataList: [],
      totalData: [],
      loading: false,
      sortName: null,
      sortDesc: null,
      showRecharge: false,
      yesterdayOptions,
      labelList,
      sendField: [],
      showField: [],
      tmpShowField: [],
      showCommonDrawer: false,
      showTemplate: null,
      nextParams: {},
      pickerOptions,
    };
  },
  methods: {
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
    // 排序处理
    handleSortChange({ column, prop, order }) {
      if (column.sortable !== "custom") {
        return;
      }
      this.sortName = prop;
      this.sortDesc = order === "descending";
      this.params.sortName = this.sortName;
      this.params.sortDesc = this.sortDesc;
      this.handlePageChange(1);
    },
    // 重置操作
    handleReset() {
      this.params.selectTime = getRecentlyWeek();
      this.sendField = [];
      this.showField = this.originFeild;
      this.tmpShowField = [];
      this.handlePageChange(1);
    },
    // 分页操作
    handlePageChange(page) {
      this.page = page;
      this.getData();
    },
    // 选择字段变化
    handleFieldChange(send, show) {
      // if (value.length === 0) {
      //   this.showField = this.originFeild;
      // } else {
      //   this.showField = value;
      // }
      // 请求格式field
      this.sendField = send;
      // 展示格式field
      this.tmpShowField = show;
    },
    // 请求前处理字段
    handleFields(item) {
      if (item.length === 0) {
        return null;
      }
      let tmpSend = [];
      const _that = this;
      tmpSend = item.reduce(function (a, b) {
        return a.concat(b);
      });
      // 判断勾选项 推入其下所有子级
      for (let i = 0; i < tmpSend.length; i++) {
        const flagIndex = _that.originFeild.findIndex((item2) => {
          return item2.fieldCode === tmpSend[i];
        });
        if (flagIndex !== -1 && "children" in _that.originFeild[flagIndex]) {
          _that.originFeild[flagIndex].children.forEach((item3) => {
            tmpSend.push(item3.fieldCode);
          });
        }
      }
      // 去重
      tmpSend = Array.from(new Set(tmpSend));
      return tmpSend.join(",");
    },
    // 广告数据获取
    getData(flag) {
      // this.params.queryDate ? '' : (this.params.queryDate = tody)
      this.loading = true;
      let tmpFields = null;
      if (this.sendField.length > 0) {
        tmpFields = this.handleFields(this.sendField);
      } else {
        this.showField = this.originFeild;
      }
      let tmpform = {};
      // 如果是创建过程 参数默认取缓存参数 否则使用筛选参数
      tmpform = {
        beginDate: this.params.selectTime[0],
        endDate: this.params.selectTime[1],
        showFieldCodes: tmpFields,
      };
      getUserEverydayRanking(tmpform)
        .then((res) => {
          // 如果用户选择过字段则表格展示用户选择字段
          if (this.tmpShowField.length > 0) {
            this.showField = this.tmpShowField;
          }
          this.dataList = res;
          // 缓存参数
          this.addRouterHistory({
            path: this.$route.path,
            params: {
              ...tmpform,
              cacheShowField: this.showField,
              cacheSendField: this.sendField,
              selectTime: this.params.selectTime,
            },
          });
        })
        .finally(() => (this.loading = false));
    },
    // 获取字段
    handleGetFeilds() {
      getFields({ dataType: "userEverydayRanking" }).then((res) => {
        this.originFeild = res;
        const tmpdata = [];
        for (let i = 0; i < res.length; i++) {
          tmpdata.push({
            fieldName: res[i].fieldName,
            fieldCode: res[i].fieldCode,
          });
        }
        this.fieldsData = tmpdata;
        if (this.showField.length === 0) {
          this.showField = res;
        }
        this.selectAllFields = true;
        this.getData("created");
        // 判断字段所选长度是否为0 是则全选 否则不全选
      });
    },
    // 处理字段
  },
  created() {
    const tody = getRecentlyWeek();
    this.$set(this.params, "selectTime", tody);
    // 缓存获取
    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );
    // 若存在缓存
    if (tmpIndex !== -1) {
      this.params = this.$store.state.routerHistory[tmpIndex].params;
      // 获取缓存展示字段
      this.showField =
        this.$store.state.routerHistory[tmpIndex].params.cacheShowField;
      // 获取缓存发送前格式字段
      this.sendField =
        this.$store.state.routerHistory[tmpIndex].params.cacheSendField;
    }
    // this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
    this.handleGetFeilds();
    // this.getData()
    clearInterval(timer);
    timer = setInterval(this.getData, 1000 * 5 * 60);
  },
  mounted() {
    // 动态设置表头位置
    document.getElementById("userRankTable").onscroll = function () {
      document.getElementById("userRankTableHeader").style.top =
        document.getElementById("userRankTable").scrollTop + "px";
    };
  },
  beforeCreate() {
    this.$options.components.CommonDrawer =
      require("@/components/commonDrawer").default;
  },
  beforeDestroy() {
    clearInterval(timer);
    timer = null;
  },
};
</script>

<style lang="scss" scoped>
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
.consume-table {
  .adId {
    text-decoration: underline;
    cursor: pointer;
  }
  .min-img {
    width: 70px;
    height: 70px;
  }
}
// 自定义表格
.customTable {
  display: flex;
  flex-wrap: nowrap;
}
.table-item {
  float: left;
}

.item-head {
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 228px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  padding-left: 10px;
  line-height: 40px;
  border: 1px solid #ebeef5;
  // border-bottom: none;
}
.single-user {
  /* margin-top: 40px; */
  border: 1px solid #ebeef5;
  width: 228px;
  height: 90px;
  padding: 5px;
  font-size: 14px;
  color: #272727;
  line-height: 20px;
}
// 表头浮动
.table-header {
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
}
.table-body {
  margin-top: 40px;
}
</style>
